<template>
  <div class="list-group personal-list-group">
    <p v-if="items.length == 0" class="text-center">
      Aún se han cargado documentos
    </p>
    <div v-else>
      <b-table
        class="table-transition"
        :items="items"
        :fields="fields"
        striped
        responsive
        small
        :tbody-transition-props="transProps"
      >
        <template #cell(titulo)="data">
          <b-link :href="data.item.url" class="url-table" target="_blank">
            {{ data.item.titulo }}
          </b-link>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import firebase from "../firebase";

export default {
  props: ["docto"],
  name: "PubListAuditorias",
  data() {
    return {
      ref: firebase
        .firestore()
        .collection("secciones")
        .doc(this.docto)
        .collection("documentos"),
      transProps: {
        // Transition name
        name: "flip-list",
      },
      items: [],
      fields: [
        { key: "anio", sortable: true, label: "Año" },
        { key: "periodo", sortable: true },
        { key: "numero", sortable: true, label: "Número" },
        { key: "titulo", sortable: true, label: "Título" },
      ],
    };
  },
  created() {
    this.ref
      .orderBy("anio", "desc")
      .orderBy("periodo")
      .orderBy("numero")
      .onSnapshot((querySnapshot) => {
        this.items = [];
        querySnapshot.forEach((doc) => {
          this.items.push({
            key: doc.id,
            ...doc.data(),
          });
        });
        //console.log(this.items);
        /*const soloAnios = this.registros.map((registro) => {
        return registro.anio;
      });
      this.listaAnios = [...new Set(soloAnios)];
      */
      });
  },
  /*
  methods: {
    getPeriodicidad(id) {
      const periodicidades = this.registros
        .filter((registro) => {
          return registro.anio === id;
        })
        .map((registro) => {
          return registro.periodicidad;
        });

      this.listaPeriodicidad = [...new Set(periodicidades)];
    },
  },*/
};
</script>
