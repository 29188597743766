<template>
  <div class="list-group personal-list-group">
    <p v-if="items.length == 0" class="text-center">
      Aún se han cargado documentos
    </p>
    <div v-else>
      <div v-if="this.estatales.length != 0">
        <h2 class="titulo-subseccion">Estatales</h2>
        <b-list-group>
          <template v-for="e in estatales">
            <b-list-group-item :href="e.url" target="_blank">
              {{ e.titulo }}
            </b-list-group-item>
          </template>
        </b-list-group>
      </div>
      <div v-if="this.nacionales.length != 0">
        <h2 class="titulo-subseccion">Nacionales</h2>
        <b-list-group>
          <template v-for="n in nacionales">
            <b-list-group-item :href="n.url" target="_blank">
              {{ n.titulo }}
            </b-list-group-item>
          </template>
        </b-list-group>
      </div>
      <div v-if="this.internacionales.length != 0">
        <h2 class="titulo-subseccion">Internacionales</h2>
        <b-list-group>
          <template v-for="i in internacionales">
            <b-list-group-item :href="i.url" target="_blank">
              {{ i.titulo }}
            </b-list-group-item>
          </template>
        </b-list-group>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../firebase";

export default {
  props: ["docto"],
  name: "PubListLinks",
  data() {
    return {
      ref: firebase
        .firestore()
        .collection("secciones")
        .doc(this.docto)
        .collection("documentos"),
      items: [],
      estatales: [],
      nacionales: [],
      internacionales: [],
    };
  },
  created() {
    this.ref.orderBy("titulo").onSnapshot((querySnapshot) => {
      this.items = [];
      querySnapshot.forEach((doc) => {
        this.items.push({
          key: doc.id,
          ...doc.data(),
        });
      });

      this.estatales = this.items.filter((item) => item.ambito === "Estatal");
      this.nacionales = this.items.filter((item) => item.ambito === "Nacional");
      this.internacionales = this.items.filter(
        (item) => item.ambito === "Internacional"
      );

      /*const soloAnios = this.registros.map((registro) => {
        return registro.anio;
      });
      this.listaAnios = [...new Set(soloAnios)];*/
    });
  },
  /*
  methods: {
    getPeriodicidad(id) {
      const periodicidades = this.registros
        .filter((registro) => {
          return registro.anio === id;
        })
        .map((registro) => {
          return registro.periodicidad;
        });

      this.listaPeriodicidad = [...new Set(periodicidades)];
    },
  },*/
};
</script>
